export function formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if(month<10){
        month='0'+month
    }
    if(day<10){
        day='0'+day
    }
    return `${date.getFullYear()}-${month}-${day}`;
}
export function dateFormat (date, format) {
    date = new Date(date);
    date.setHours(date.getHours());
    var o = {
        'M+' : date.getMonth() + 1, //month
        'd+' : date.getDate(), //day
        'H+' : date.getHours(), //hour
        'm+' : date.getMinutes(), //minute
        's+' : date.getSeconds(), //second
        'q+' : Math.floor((date.getMonth() + 3) / 3), //quarter
        'S' : date.getMilliseconds() //millisecond
    };

    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

    for (var k in o)
        if (new RegExp('(' + k + ')').test(format)){
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
        }
    return format;
}

/**
 * 获取当前月的最后一天
 * @returns {Date}
 */
export function getCurrentMonthLast(d){
    var date=new Date(d);
    var currentMonth=date.getMonth();
    var nextMonth=++currentMonth;
    var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
    var oneDay=1000*60*60*24;
    var lastTime = new Date(nextMonthFirstDay-oneDay);
    var month = parseInt(lastTime.getMonth()+1);
    var day = lastTime.getDate();
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }
// 时间挫转时分秒
export const numberToDate = (num)=>{
    let day = plug(Math.trunc(num / (3600 * 24 * 1000)));
    let h = plug(Math.trunc((num - 3600 * 24 * 1000 * day) / (3600 * 1000)));
    let m = plug(Math.trunc((num - 3600 * 24 * 1000 * day - 3600 * 1000 * h) / (60 * 1000)));
    let s = plug(Math.trunc((num - 3600 * 24 * 1000 * day - 3600 * 1000 * h - 60 * 1000 * m) / 1000));
    return {
      day: day,
      h: h,
      m: m,
      s: s
    }
  }
  function plug(str){
    if (str.toString().length==1){
      return `0${str}`;
    }else{
      return str;
    }
  }